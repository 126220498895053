var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_stock_adjustment") } },
    [
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-form-model",
            _vm._b(
              { attrs: { model: _vm.form, "label-align": "left" } },
              "a-form-model",
              _vm.formItemLayout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t(_vm.formRules.no.label) } },
                [
                  _c("a-input", {
                    attrs: {
                      name: _vm.formRules.no.name,
                      placeholder: _vm.$t(_vm.formRules.no.placeholder),
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.form.documentNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "documentNumber", $$v)
                      },
                      expression: "form.documentNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t(_vm.formRules.location.label) } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        name: _vm.formRules.location.name,
                        placeholder: _vm.$t(_vm.formRules.location.placeholder),
                        "show-search": true,
                        "filter-option": false,
                        loading: _vm.loadingLocation,
                        "allow-clear": true
                      },
                      on: {
                        search: function(value) {
                          return _vm.getListLocation(value)
                        }
                      },
                      model: {
                        value: _vm.form.location,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "location", $$v)
                        },
                        expression: "form.location"
                      }
                    },
                    _vm._l(_vm.dataListLocation, function(data, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: data.id } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      data.warehouse.branchWarehouse.name +
                                        " - " +
                                        data.warehouse.name +
                                        " - " +
                                        data.name
                                    ) +
                                    " "
                                )
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    data.warehouse.branchWarehouse.name +
                                      " - " +
                                      data.warehouse.name +
                                      " - " +
                                      data.name
                                  ) +
                                  " "
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t(_vm.formRules.adj.label) } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        placeholder: _vm.$t(_vm.formRules.adj.placeholder),
                        "allow-clear": true
                      },
                      model: {
                        value: _vm.form.type,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type"
                      }
                    },
                    _vm._l(_vm.optType, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.key, attrs: { value: item.value } },
                        [_vm._v(" " + _vm._s(item.key) + " ")]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t(_vm.formRules.date.label) } },
                [
                  _c("a-range-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      format: _vm.DEFAULT_DATE_FORMAT,
                      type: "date",
                      placeholder: [
                        _vm.$t(_vm.formRules.date.placeholder),
                        _vm.$t(_vm.formRules.date.placeholder)
                      ]
                    },
                    model: {
                      value: _vm.form.date,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "date", $$v)
                      },
                      expression: "form.date"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleClear } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_clear")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            loading: _vm.loadingSubmit,
                            type: "primary"
                          },
                          on: { click: _vm.handleFind }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { align: "end" } },
                [
                  _vm.hasPrivilegeCreate
                    ? _c(
                        "a-button",
                        {
                          attrs: { icon: "plus", type: "primary" },
                          on: { click: _vm.handleCreate }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_create_new")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                [
                  _c("TableCustom", {
                    attrs: {
                      "data-source": _vm.dataSource,
                      columns: _vm.columnsTable,
                      scroll: { x: "calc(300px - 50%)", y: 500 },
                      paginationcustom: true,
                      "default-pagination": false,
                      loading: _vm.loadingTable
                    },
                    on: {
                      "on-edit": _vm.responseEditTable,
                      "on-view": _vm.responseViewTable,
                      "on-columnClicked": _vm.handleClickColumn
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            {
              attrs: {
                gutter: [16, 16],
                type: "flex",
                align: "middle",
                justify: "space-between"
              }
            },
            [
              _c(
                "a-col",
                { attrs: { sm: 24, md: 16 } },
                [
                  _c("Pagination", {
                    attrs: {
                      total: _vm.totalElements,
                      "page-size-set": _vm.paramStock.limit,
                      "id-pagination": "pagination1"
                    },
                    on: {
                      "response-pagesize-change": _vm.responsePageSizeChange,
                      "response-currentpage-change":
                        _vm.responseCurrentPageChange
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 24, md: 8, align: "end" } },
                [
                  _c("a-tag", { attrs: { color: "#8c8c8c" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lbl_total_found")) +
                        " : " +
                        _vm._s(_vm.totalElements) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            icon: "download",
                            type: "primary",
                            loading: _vm.loadingDownload
                          },
                          on: { click: _vm.handleDownload }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            icon: "printer",
                            type: "primary",
                            loading: _vm.loadingPrint
                          },
                          on: { click: _vm.handlePrint }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }