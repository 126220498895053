









































































































































































// core
import Vue from "vue";
import moment from "moment";
import printJS from "print-js";
// services
import { stockAdjustmentService } from "@/services-v2/stock-adjustment.service";
import { warehouseLocationService } from "@/services-v2/warehouse-location.service";
import {generalJournalServices} from "@/services-v2/general-journal.service";
// interface
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponsePagination } from "@/models/interface/common.interface";
import { ResponseListStockAdjustment, ResponseStockAdjustment } from "@/models/interface-v2/stock-adjustment.interface";
import { ResponseListWarehouseLocation, ResponseWarehouseLocation } from "@/models/interface-v2/warehouse.interface";
// util
import { debounceProcess } from "@/helpers/debounce";
// constant
import { DEFAULT_DATE_FORMAT } from "@/models/constant/date.constant";
import { SEARCH_STOCK_TYPE } from "@/models/enums/stock-adjustment.enum";
import { mapGetters } from "vuex";
import { getauthorities } from "@/helpers/cookie";

const OPT_TYPE = [
  {
    key: "Stock Adjustment",
    value: SEARCH_STOCK_TYPE.STOCK_ADJUSTMENT
  },
  {
    key: "Stock Opname",
    value: SEARCH_STOCK_TYPE.STOCK_OPNAME
  }
];

export default Vue.extend({
  name: "ListStockAdjustment",
  data() {
    this.getListLocation = debounceProcess(this.getListLocation, 200);
    return {
      dataSource: [] as ResponseStockAdjustment[],
      columnsTable: [
        {
          title: this.$t("lbl_document_number"),
          dataIndex: "documentNumber",
          key: "documentNumber",
        },
        {
          title: this.$t("lbl_location"),
          dataIndex: "warehouseLocation",
          key: "warehouseLocation",
        },
        {
          title: this.$t("lbl_type"),
          dataIndex: "type",
          key: "type",
        },
        {
          title: this.$t("lbl_date"),
          dataIndex: "date",
          key: "date",
        },
        {
          title: this.$t("lbl_journal_number"),
          dataIndex: "journalNumber",
          key: "journalNumber",
          scopedSlots: { customRender: "clickColumn" }
        },
        {
          title: this.$t("lbl_status"),
          dataIndex: "state",
          key: "state",
          width: "100px",
          fixed: "right",
          align: "center",
        },
      ] as any,
      totalElements: 0 as number,
      loadingTable: false as boolean,
      loadingSubmit: false as boolean,
      loadingLocation: false as boolean,
      loadingDownload: false as boolean,
      loadingPrint: false as boolean,
      DEFAULT_DATE_FORMAT,
      optType: OPT_TYPE,
      dataListLocation: [] as ResponseWarehouseLocation[],
      form: {
        documentNumber: "",
        location: "",
        type: "",
        date: []
      },
      formRules: {
        no: {
          label: "lbl_document_number",
          name: "Document Number",
          placeholder: "lbl_type_here",
        },
        location: {
          label: "lbl_location",
          name: "Location",
          placeholder: "lbl_choose",
        },
        adj: {
          label: "lbl_adjustment_type",
          name: "Adj. Type",
          placeholder: "lbl_choose",
        },
        date: {
          label: "lbl_date",
          name: "Date",
          placeholder: "lbl_choose",
        },
      },
      paramStock: {
        limit: 10,
        page: 0,
        sorts: "createdDate:desc"
      } as RequestQueryParamsModel
    };
  },
  computed: {
    ...mapGetters({
      getUserPrivileges: "authStore/GET_USER_PRIVILEGES",
    }),
    hasPrivilegeCreate(): boolean {
      return this.getUserPrivileges.find(x => x.key === "stock-adjustment" && x.privilege.update && x.privilege.create);
    },
    formItemLayout() {
      return {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
      };
    },
  },
  created() {
    this.setAuthorities();
    this.getListLocation("");
    this.getListStock();
  },
  methods: {
    moment,
    setAuthorities(): void {
      const auth = getauthorities();
      auth.forEach(dataAuth => {
        if(dataAuth.actions?.length && dataAuth?.name === "stock-adjustment") {
          this.authPrivilege = dataAuth.value;
          this.columnsTable.push(
            {
              title: this.$t("lbl_action"),
              dataIndex: "operation",
              key: "operation",
              scopedSlots: { customRender: "operation" },
              button: dataAuth.actions,
              width: "120px",
              align: "center",
              fixed: "right",
            },
          );
        }
      });
    },
    async handleClickColumn(record, objColumnNameValue) {
      let data;
      const paramsJournal = {
        page: 0,
        limit: 10,
        search: `name~${record.journalNumber}`,
        sorts: "createdDate:desc",
      } as RequestQueryParamsModel;
      if (objColumnNameValue.column === "journalNumber") {
        data = await generalJournalServices.listGeneralJournal(paramsJournal);
        if (data.data.length > 0) {
          this.$confirm({
            title: "You will be redirected to another page, are you sure?",
            onOk: () => {
              this.$router.push(`/generaljournal/journal/detail/${data.data[0].id}`);
            },
            onCancel() {
              return;
            },
          });
        }
      }
    },
    handleDownload(): void {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: this.totalElements
      };
      this.loadingDownload = true;
      stockAdjustmentService
        .downloadStockAdjustment(params)
        .then(response => {
         if (response) {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "stock_adjustment.xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
       })
       .finally(() => this.loadingDownload = false);
    },
    async handlePrint(): Promise<void> {
      try {
        const params: RequestQueryParamsModel = {
          page: 0,
          limit: this.totalElements,
          sorts: "createdDate:desc"
        };
        if (this.paramStock.search) params.search = this.paramStock.search;
        this.loadingPrint = true;
        const res = await this.getStockAdjustment(params);
        const properties = [
          "documentNumber",
          "warehouseLocation",
          "type",
          "date",
          "journalNumber",
          "state"
        ];
        printJS({
          printable: res.data,
          properties,
          type: "json",
          gridHeaderStyle: "border: 1px solid #000",
          gridStyle: "text-align: center;border: 1px solid #000",
          onError: (error) => {
            this.$notification.error({
              message: this.$t("lbl_error_title").toString(),
              description: error.message
            });
          }
        });
      } catch (error) {
        this.$message.error(this.$t("notif_process_fail").toString());
      } finally {
        this.loadingPrint = false;
      }
    },
    responseEditTable (response) {
      this.$router.push(`/logistic/stockadjustment/edit/${response.data.id}`);
    },
    responseViewTable (response) {
      this.$router.push({name: "logistic.stockadjustment.view", params: { id: response.data.id }, query: { status: response.data.state.toLowerCase() }});
    },
    handleCreate(): void {
      this.$router.push({ name: "logistic.stockadjustment.create", query: { status: "new" } });
    },
    responsePageSizeChange (response: ResponsePagination) {
      this.paramStock.limit = response.size;
      this.paramStock.page = 0;
      this.getListStock();
    },
    responseCurrentPageChange (response: ResponsePagination) {
      this.paramStock.page = response.page - 1;
      this.getListStock();
    },
    handleClear(): void {
      this.form = {
        documentNumber: "",
        location: "",
        type: "",
        date: []
      };
      this.paramStock.page = 0;
      this.paramStock.search = "";
      this.getListStock();
    },
    getListLocation(valueSearch: string) {
      let params: RequestQueryParamsModel = {
        page: 0,
        limit: 10
      };

      if (valueSearch) {
        params.search = `warehouse.branchWarehouse.name~*${valueSearch}*_OR_warehouse.branchWarehouse.code~*${valueSearch}*_OR_warehouse.branchWarehouse.address~*${valueSearch}*_OR_warehouse.name~*${valueSearch}*_OR_name~*${valueSearch}*`;
      }
      this.loadingLocation = true;
      warehouseLocationService.listWarehouseLocation(params)
      .then((data: ResponseListWarehouseLocation) => {
        this.dataListLocation = data.data;
      })
      .finally(() => this.loadingLocation = false);
    },
    getStockAdjustment(param: RequestQueryParamsModel): Promise<ResponseListStockAdjustment> {
      return stockAdjustmentService.getStockAdjustment(param);
    },
    async getListStock(): Promise<void> {
      try {
        this.loadingTable = true;
        const res = await this.getStockAdjustment(this.paramStock);
        this.dataSource = res.data;
        this.dataSource.forEach((el, i) => {
          el["key"] = i;
          el.date = el.date ? moment(el.date).format(DEFAULT_DATE_FORMAT) : "";
        });
        this.totalElements = res.totalElements;
      } finally {
        this.loadingTable = false;
      }
    },
    buildDateParam(rangeDate: string[] = []): string[] {
      const start = this.moment(rangeDate[0]).set({ hour: 0, minute: 0, second: 0}).utcOffset("+07").format();
      const end = this.moment(rangeDate[1]).set({ hour: 23, minute: 59, second: 59}).utcOffset("+07").format();
      return [start, end];
    },
    handleFind(): void {
      const searchBy: string[] = [];
      const form = {...this.form};
      if (form.documentNumber) searchBy.push(`documentNumber~${form.documentNumber}`);
      if (form.location) searchBy.push(`warehouseLocation.secureId~${form.location}`);
      if (form.type) searchBy.push(`type~${form.type}`);
      if (form.date.length) {
        const [start, end] = this.buildDateParam(form.date);
        searchBy.push(`date>=${start}_AND_date<=${end}`);
      }
      this.paramStock.search = searchBy.join("_AND_");
      this.getListStock();
    }
  }
});
